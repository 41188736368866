import React, { FormEvent, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.js';
import Logo from './logo4.png';
import LogoU from './logo-ufms.png';
import LogoA from './logo-agetic.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import Captcha from './captcha.jpg';
import InputMask from 'react-input-mask';
import { Alert } from 'react-bootstrap';

const Home = () => {
  const [captcha, setCaptcha] = useState(false);
  const [rga, setRga] = useState('');
  const [nome, setNome] = useState('');
  const [data, setData] = useState('');
  const [horario, setHorario] = useState('');
  const [codigo, setCodigo] = useState('');
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const validar = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (codigo.toUpperCase() === '3N3Y.T9SB.4QHY.2XW6' && nome.toUpperCase() === 'VICTOR HUGO DE SOUSA LIMA' && horario === '07:51:14' && data === '17/10/2024' && rga === '2017.0743.015-1' && captcha) {
      await delay(1000); 
      setShow(true);
      setSuccess(true);
    } else {
      await delay(500);
      setShow(true);
      setSuccess(false);
    }
  }

  return (
    <>
      <header>
        <nav className='navbar navbar-nti'>
          <div className='container-fluid'>
            <div className='navbar-header text-center d-flex'>
              <img src={Logo} className='logoheader' />
              <a className='navbar-brand nome-sistema d-flex align-items-center'><>Portal do Aluno</></a>
            </div>
          </div>
        </nav>
      </header>
      <section className='container-fluid hidden-print'>
      </section>
      <div className='d-flex justify-content-center'>
        <div className='col-xs-12 col-md-8 col-md-offset-2'>
          <div className='box box-primary'>
            <div className='box-header'>
              <FontAwesomeIcon icon={faCertificate}  className='my-auto me-1'/>
              <h4 className='box-title'>Validar documentos emitidos pelo Sistema Acadêmico da UFMS</h4>
            </div>
            <div className='box-body'>
              <form className='form-horizontal' onSubmit={validar}>
                <div className="form-group text required row mb-3">
                  <label className="control-label col-md-3" htmlFor="rga">RGA <span>*</span></label>
                  <div className="col-md-6">
                    <InputMask mask="9999.9999.999-9" type="text" name="rga" className="form-control form-control rga" required tabIndex={1} maxLength={17} id="rga" value={rga} placeholder="____.____.___-_" onChange={(e) => setRga(e.target.value)}/>
                  </div>
                </div>
                <div className="form-group text required row mb-3">
                  <label className="control-label col-md-3" htmlFor="nome">Nome completo do acadêmico <span>*</span></label>
                  <div className="col-md-6"><input type="text" name="nome" className="form-control form-control" style={{textTransform:'uppercase'}} required maxLength={80} tabIndex={2} title="Informar igual ao documento. Caso possua nome social, escrever o nome social e o nome civil entre parênteses." id="nome" value={nome} onChange={(e) => setNome(e.target.value)}/>
                  </div>
                </div>
                <div className="form-group text required row mb-3">
                  <label className="control-label col-md-3" htmlFor="data">Data de emissão <span>*</span></label>
                  <div className="col-md-6">
                    <InputMask mask="99/99/9999" type="text" name="data" className="form-control form-control data" required maxLength={12} tabIndex={3} id="data"  placeholder="__/__/____" value={data} onChange={(e) => setData(e.target.value)}/>
                  </div>
                </div>
                <div className="form-group text required row mb-3">
                  <label className="control-label col-md-3" htmlFor="hora">Horário de emissão <span>*</span></label>
                  <div className="col-md-6">
                    <InputMask mask="99:99:99" type="text" name="hora" className="form-control form-control hora" required tabIndex={4} id="hora" value={horario} onChange={(e) => setHorario(e.target.value)} placeholder='__:__:__'/>
                  </div>
                </div>
                <div className="form-group text required row mb-4">
                  <label className="control-label col-md-3" htmlFor="codigo">Código <span>*</span></label>
                  <div className="col-md-6">
                    <InputMask mask="****.****.****.****" type="text" name="codigo" className="form-control form-control codigo" style={{textTransform:'uppercase'}} required tabIndex={5} id="codigo" placeholder="____.____.____.____" value={codigo} onChange={(e) => setCodigo(e.target.value)}/>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className='captcha d-flex justify-content-between px-1 py-1'>
                    <div className='captcha-content d-flex'>
                      <input type="checkbox" id="checkbox" className="captcha-checkbox2" checked={captcha} onClick={() => setCaptcha(!captcha)}/>
                      <label className='my-auto ms-1'>Não sou um robô</label>
                    </div>
                    <img src={Captcha} className='img-captcha' onClick={() => {window.location.href = 'https://policies.google.com/privacy?hl=pt-BR'}}/>
                  </div>
                </div>
                <div className='form-group d-flex justify-content-center mt-4 mb-3'>
                  <button className='btn btn-success mt-1' type='submit'>Validar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid col-xs-12 col-md-8 '>
        {show && success &&
          <Alert variant='success' dismissible onClose={() => setShow(false)}>
              <div className="d-flex justify-content-center">
                O documento informado é válido. Segue abaixo cópia do documento para verificação.
              </div>
            </Alert>
        }
        {show && !success && 
          <Alert variant='danger' dismissible onClose={() => setShow(false)} >
            <div className='d-flex justify-content-center danger-text'>
              Documento inválido. Por favor, verifique se os dados foram informados corretamente.
            </div>
          </Alert>
        }
      </div>
      
        <div className='container col-xs-12 col-md-8 pb-4 mb-5 mt-5 phonefix'>
            {success && 
            <iframe
                src={`${process.env.PUBLIC_URL}/274648.pdf`}
                width="100%"
                height="750px"
                style={{ border: 'none' }}
                title="PDF Viewer"
            />
            }
        </div>
      <footer className="main-footer d-flex">
        <a className="col-sm-3 col-xs-3" href="" target="_blank">
          <img src={LogoU} alt="UFMS" height="40px" className='ms-3'/>    
        </a>
        <p className="text-center small col-sm-6 col-xs-6" style={{fontSize: '11px'}}>   
            <strong>AGETIC - Agência de Tecnologia da Informação e Comunicação <br/>
                UFMS - Universidade Federal de Mato Grosso do Sul</strong><br/>
        </p>
        <a className="col-sm-3 col-xs-3 text-right d-flex justify-content-end pe-3" href="" target="_blank">
          <img src={LogoA} alt="AGETIC" height={'40px'}/>    
        </a>
      </footer>
    </>
  );
}

export default Home;
