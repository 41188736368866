import {Routes, Route} from 'react-router-dom';
import Home from './Home';
import Validate from './Validate';

export default function mainRoutes(){
    return(
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/validar/:id' element={<Validate/>}/>
            <Route path='/validar' element={<Validate/>}/>
        </Routes>
    )
}